import React from 'react';
import styled from 'styled-components';
import PageBase from '../components/PageBase';
import Container from '../components/Container';
import Button from '../components/Button';
import servicesImg from '../images/services.jpg';
import premiumLicensingImg from '../images/premium-licensing.svg';
import privateSuccesionImg from '../images/private-succesion.svg';
import chevronImg from '../images/chevron-small.svg';
import checkmarkIcon from '../images/checkmark.svg';
import servicesContactImg from '../images/services-contact.svg';

export default function Services() {
	return (
		<PageBase>
			<StyledFirstSection>
				<Container>
					<div>
						<h2>Licensing Options + Succession</h2>
						<p>
							NEO is providing top tier licensing options, complete client management solutions or a private succession plan. Learn about
							our service plans below.
						</p>
					</div>
					<img data-sal="fade" data-sal-delay="0" data-sal-duration="1000" data-sal-easing="ease-in-out" src={servicesImg} alt="Services" />
				</Container>
			</StyledFirstSection>
			<StyledSecondSection>
				<Container>
					<div className="service light-bg" data-sal="slide-up" data-sal-delay="400" data-sal-duration="500" data-sal-easing="ease-in-out">
						<Container>
							<div className="service-main">
								<div className="service-main-content">
									<div className="service-main-content-top">
										<img src={premiumLicensingImg} alt="Premium licensing" />
										<h3 className="service-main-content-title">Premium Licensing</h3>
										<p className="service-main-content-description">
											A flat dollar licensing fee paid as a representative of the NEO AFSL to run and build your business
										</p>
									</div>
									<div className="service-main-content-bottom">
										<div className="service-main-content-line" />
										<p className="service-main-content-join">
											Join as a Corporate Authorised Representative (CAR) and/or Authorised Representative (AR).
										</p>
									</div>
								</div>
								<div className="service-main-footer">
									<p>Plan details</p>
									<img src={chevronImg} alt="Plan details" />
								</div>
							</div>
							<div className="service-extend">
								<div>
									<h3 className="service-extend-title">You pay:</h3>
									<p className="service-extend-description">$27,500 p.a. dealership costs plus 3rd party costs (PI, ASIC Levy, etc)</p>
									<h3 className="service-extend-list-title">We provide</h3>
									<ul className="service-extend-list">
										<li>Business risk review and assessment</li>
										<li>Ongoing education (CPD) & Professional Development</li>
										<li>Advisor technical resources and technology solutions</li>
										<li>Compliance & Audit programs</li>
										<li>Research & open approved product list</li>
										<li>Commission administration</li>
										<li>Statement of Advice (SoA) & advice vetting service.</li>
									</ul>
								</div>
								<h3 className="service-extend-footer-title">100% revenue to you</h3>
							</div>
						</Container>
					</div>

					<div className="service dark-bg" data-sal="slide-up" data-sal-delay="600" data-sal-duration="500" data-sal-easing="ease-in-out">
						<Container>
							<div className="service-main">
								<div className="service-main-content">
									<div className="service-main-content-top">
										<img src={privateSuccesionImg} alt="Premium licensing" />
										<h3 className="service-main-content-title">Private Succession</h3>
										<p className="service-main-content-description">
											A beneficial exit for you, and best in class ongoing service for your clients.
										</p>
									</div>
									<div className="service-main-content-bottom">
										<div className="service-main-content-line" />
										<p className="service-main-content-join">
											Full sale; <br />
											Partial sale; or <br /> Transitional sale.
										</p>
									</div>
								</div>
								<div className="service-main-footer">
									<p>Plan details</p>
									<img src={chevronImg} alt="Plan details" />
								</div>
							</div>
							<div className="service-extend">
								<div>
									<h3 className="service-extend-subtitle">
										NEO’s parent company, PictureWealth, is an active business acquirer and offers a customised succession plan to create a
										beneficial exit for you, and a value add for your clients.{' '}
									</h3>

									<p className="service-extend-subdescription">
										Choosing the right partner when selling your business eliminates worry and uncertainty.
									</p>
									<p className="service-extend-subdescription">
										PictureWealth’s flexible approach is supported by in-house legal counsel, diligence, and finance teams, providing you
										with the resources you need to make the best decision for your business
									</p>
								</div>
								<h3 className="service-extend-footer-title">CERTAINTY, CONTROL & A BENEFICIAL OUTCOME</h3>
							</div>
						</Container>
					</div>
				</Container>
			</StyledSecondSection>
			<StyledThirdSection>
				<Container>
					<img
						data-sal="slide-right"
						data-sal-delay="100"
						data-sal-duration="1000"
						data-sal-easing="ease-in-out"
						src={servicesContactImg}
						alt="Contact us"
					/>
					<div className="services-contact-content-wrapper">
						<div className="services-contact-content">
							<p>Implemented via our signature Client Transfer & Purchase Agreement.</p>
						</div>
						<div className="chat-buttons">
							<Button to="/contact" value="Send us a message" colorScheme="light" size="big" className="send-us-a-message-btn" />
							<Button href="tel:+610894447494" value="Call us (08) 9444 7494" colorScheme="light" size="big" className="call-us-btn" />
						</div>
					</div>
				</Container>
			</StyledThirdSection>
		</PageBase>
	);
}

const StyledFirstSection = styled.section`
	background-color: #f8f9fb;
	& .container {
		padding-top: 60px;
		h2 {
			font-size: 32px;
			font-weight: 700;
			text-align: center;
			color: #192851;
			line-height: 40px;
			max-width: 280px;
			margin: 0 auto;
		}

		p {
			font-size: 14px;
			color: #49667b;
			line-height: 25px;
			max-width: 290px;
			text-align: center;
			margin: 0 auto;
			margin-top: 30px;
		}
		img {
			margin-top: 50px;
			width: 100%;
			height: auto;
		}
	}

	@media screen and (min-width: 768px) {
		& .container {
			padding-top: 0;
			justify-content: space-between;
			align-items: center;
			h2 {
				text-align: left;
				max-width: 80%;
				font-size: 46px;
				line-height: 57px;
			}
			p {
				text-align: left;
				max-width: 80%;
				font-size: 15px;
			}
			img {
				width: 50%;
				position: relative;
				left: 10%;
			}
		}
	}
`;

const StyledSecondSection = styled.section`
	padding-top: 50px;

	& > .container {
		width: 100% !important;
		display: flex;
		flex-direction: column;
	}

	& .service.light-bg {
		& .service-main {
			background-color: #823fd3;

			& .service-main-footer {
				background-color: #00adcb;
			}
		}
		& .service-extend {
			&-title,
			&-list-title,
			&-footer-title {
				color: #823fd3;
			}
		}
	}
	& .service.medium-bg {
		& .service-main {
			background-color: #2f9bc0;

			& .service-main-footer {
				background-color: #0c7ba1;
			}
		}
		& .service-extend {
			&-title,
			&-list-title,
			&-footer-title {
				color: #2f9bc0;
			}
		}
	}
	& .service.dark-bg {
		& .service-main {
			background-color: #192851;

			& .service-main-footer {
				background-color: #003062;
			}
		}
		& .service-extend {
			&-title,
			&-list-title,
			&-footer-title {
				color: #192851;
			}
		}
	}

	& .service {
		flex: 1;
		margin-top: 25px;
		& .container {
			flex-direction: column !important;
			width: 100% !important;

			& .service-main {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				&-content {
					display: flex;
					justify-content: flex-start;
					flex-direction: column;
					height: 100%;

					&-top {
						min-height: 300px;
						padding: 40px 40px 0 40px !important;
					}

					&-bottom {
						padding: 0px 40px 40px 40px;
					}
					&-top,
					&-bottom {
						box-sizing: border-box;
					}

					& img {
						width: 70px;
						height: auto;
					}

					&-title {
						font-size: 35px;
						color: white;
						font-weight: 700;
						line-height: 40px;
						margin-top: 35px;
					}

					&-description {
						font-size: 15px;
						font-weight: 400;
						color: white;
						margin-top: 15px;
						line-height: 25px;
					}

					&-line {
						width: 100%;
						height: 1px;
						background-color: white;
						margin-bottom: 30px;
					}

					&-join {
						font-weight: 700;
						font-size: 15px;
						color: white;
						line-height: 25px;
					}
				}
				&-footer {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;
					padding: 18.5px 0;
					margin-top: auto;

					p {
						color: white;
						font-size: 15px;
						font-weight: 700;
						text-transform: uppercase;
						text-align: center;
					}

					img {
						display: none;
					}
				}
			}
		}

		&-extend {
			box-sizing: border-box;
			padding: 40px;
			background-color: #f8f9fb;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			&-title {
				font-weight: 700;
				font-size: 24px;
				line-height: 51px;
			}

			&-subtitle {
				font-size: 20px;
				line-height: 30px;
				font-weight: 400 !important;
				color: #2b4659;
			}

			&-description,
			&-subdescription {
				color: #2b4659;
				font-weight: 400;
				font-size: 13px;
				line-height: 25px;
				margin-top: 15px;
			}

			&-subdescription:first-of-type {
				margin: 30px 0;
			}

			&-list-title {
				font-size: 24px;
				font-weight: 700;
				line-height: 51px;
			}

			&-list {
				list-style: none;

				li {
					font-weight: 400;
					color: #2b4659;
					font-size: 13px;
					margin: 15px 0 15px 20px;
					position: relative;
					left: 20px;
					display: flex;

					&::before {
						content: '';
						position: absolute;
						left: -40px;
						top: 0;
						margin-right: 20px;
						width: 16px;
						height: 14px;
						background-size: cover;
						background-repeat: no-repeat;
						background-image: url(${checkmarkIcon});
					}
				}
			}

			&-footer-title {
				text-transform: uppercase;
				font-size: 24px;
				font-weight: 700;
				line-height: 32px;
				text-transform: uppercase;
				margin-top: 30px;
			}
		}
	}

	@media screen and (min-width: 992px) {
		& .container {
			flex-direction: row !important;
		}

		& .service > .container {
			width: 90% !important;
			height: 100%;
		}
		.service-main {
			min-height: 650px;
			&-content {
				padding-bottom: 0 !important;
			}
		}

		.service-main-content-top {
			min-height: 400px !important;
			padding: 0 40px !important;
		}
		.service-main-content-bottom {
			padding: 0 40px !imporant;
		}
	}

	@media screen and (min-width: 1200px) {
		.service {
			display: flex;

			&-main {
				min-height: 550px;

				&-content-top {
					min-height: 350px !important;
				}
			}
		}
	}
`;

const StyledThirdSection = styled.section`
	background-color: #f8f9fb;
	padding: 50px 0;
	margin-top: 10px;

	& .container {
		flex-direction: column;
		h3 {
			text-align: center;
			color: #192851;
			font-size: 18px;
			font-weight: 700;
			margin-top: 40px;
		}

		p {
			text-align: center;
			color: #67839f;
			font-size: 13px;
			font-weight: 400;
			line-height: 25px;
			margin-top: 30px;
		}

		& .chat-buttons {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	@media screen and (min-width: 992px) {
		width: 100%;
		margin-top: 50px;
		padding: 0;

		& .container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			& .services-contact-content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;

				& h3 {
					text-align: left;
					margin-top: 40px;
				}
				& p {
					text-align: left;
				}
			}

			img {
				width: 60%;
				height: auto;
			}

			& .chat-buttons {
				display: flex;
				flex-direction: row;
				margin-bottom: 40px;
				justify-content: flex-start;

				& .call-us-btn {
					margin-left: 20px;
				}
			}
		}
	}

	@media screen and (min-width: 1400px) {
		& .container {
			& .services-contact-content-wrapper {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;

				h3 {
					margin-top: 0;
					max-width: 90%;
				}

				& .chat-buttons {
					flex-direction: column;

					& .call-us-btn {
						margin-left: 0;
					}
				}
			}
		}
	}
`;
